.blog-container {
    max-width: rem(700);
    margin: 0 auto rem(100);
    padding: rem(80) 0 0 0;
    position: relative;
}

.blog-entry {
    margin-bottom: rem(40);
    padding-bottom: rem(30);
    background-size: 60% 2px;
    background-origin: padding-box;
    background-position: center 100%;
    background-repeat: no-repeat;
}
.tag-results {
  padding-top: 0;
  margin: 140px auto 0;
  max-width: rem(700);
  @media screen and (max-width: em(720)) {
    margin: 140px rem(10);
  }
  @media screen and (max-width: em(560)) {
    margin: 160px rem(10) 0;
  }
  .blog-entry-text {
    padding: 0;
  }
} 
.blog-single-entry {
    margin-top: rem(60);

    @media screen and (max-width:em(720)) {
        margin-top: rem(84);
        padding-top: 0;
    }

    @media screen and (max-width:em(560)) {
        margin-top: rem(111);
    }
}
.blog-headline {
    font-size: em(26);
    font-family: $sans;
    margin: 0 rem(10) rem(20);
    padding-top: rem(40);
    text-align: center;

    @media screen and (max-width:em(500)) {
        font-size: em(24);
    }
    a {
        color: $body;
        background-image: none;

        &:hover {
            color: $aqua;
        }
        @media (prefers-color-scheme: dark) {
			color:$dm-light;
		}
    }
}
.blog-entry-date {
    text-align: center;
    font-style: italic;
    font-size: em(15);
    margin-bottom: rem(18);
}

.blog-read-time {
    text-align: center;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-size: rem(12);
    margin-bottom: rem(15);

    &::before {
        display: block;
        width: rem(150);
        content: " ";
        height: rem(1);
        border-top: 1px solid $med;
        margin: 0 auto rem(8);
    }
}

.blog-image {
    width: 100%;
    max-width:700px;
    margin: 0 auto;
    position: relative;
    padding: 58.8% 0 0;
    background-color: $xxlight;
    @media (prefers-color-scheme: dark) {
        $background-color: $dark;
    }
    @media screen and (max-width:rem(430)) {
        margin-top: -20px;
    }

    img {
        width: 100%;
        position: absolute;
        top: 0;
    }
}