.headspace-wrapper {
    height: rem(50);
    width: 100%;
    position: absolute;
    top: rem(9);
    left: 0;
    overflow: hidden;
    display: block;
    pointer-events: all;

    @media screen and (min-width:rem(560)) {
        display: none;
    }
}

.nav-fixed-headspace {
    height: rem(50);
    padding: 0 rem(15);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    transform: translateY(rem(80));
    overflow: hidden;
}

.nav-blog-headline {
    margin: 0;
    padding: 0;
    font-size: rem(18);
    max-height: rem(50);
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(rem(80));
    width: 100%;
    font-family: $sans;
}

.blog-entry-text {
    position: relative;
    @media screen and (max-width:em(728)) {
        padding: 0 rem(20);
    }
    @media screen and (max-width:em(500)) {
        padding: 0 rem(15);
    }
    p {
        font-size: em(16);
        @media screen and (max-width:em(500)) {
            font-size: em(15);
        }
    }

    p:first-child:first-letter {
        float: left;
        color: $med;
        font-size: em(53);
        line-height: 80%;
        margin: rem(5) rem(5) 0 0;
        font-family: $sans;

        @media screen and (max-width:em(500)) {
            font-size: em(53);
        }

        @media (prefers-color-scheme: dark) {
            color: $x-light-aqua;
        }
    }

    ul {
        margin: 15px 20px rem(30);

        li {
            margin: 0 0 8px 0px;
            font-size: em(16);
            line-height: 150%;

            //	text-indent:-24px;
            @media screen and (max-width:em(500)) {
                font-size: em(15);
            }
            &::before {
                width: rem(18);
                height: rem(18);
                background-color: lighten($aqua, 45%);
                content: ' ';
                display: inline-block;
                margin: 10px 4px 0 0;
                border-radius: 50%;
                vertical-align: sub;
            }

            @media (prefers-color-scheme: dark) {
                &::before {
                    background-color: $light_aqua;
                }
            }
        }
    }
    blockquote {
        font-size: em(16);
        line-height: 130%;
        border-left: 1px solid $aqua;
        margin-left: 15px;
        padding-left: 10px;
        color: $dark;

        @media screen and (max-width:em(500)) {
            font-size: em(15);
        }
        @media ( prefers-color-scheme: dark) {
            color:$dm-light;
        }
        p:first-child:first-letter {
            float: none;
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            margin: 0;
            font-family: inherit;

            @media screen and (max-width:em(500)) {
                font-size: inherit;
            }
        }
    }
    h3 {
        font-family: $sans;
        font-size: em(20);
        color: $dark;
        margin: 0 0 12px;
         @media ( prefers-color-scheme: dark) {
            color:$x-light-aqua;
        }
    }
}
.blog-entry-text-index {
    max-height: rem(350);
    overflow-y: hidden;
    position: relative;
    padding: 0;
    @media screen and (max-width:em(500)) {
        max-height: rem(500);
        margin: 0 rem(15);
    }
    &::before {
        width: 100%;
        height: 150px;
        display: block;
        position: absolute;
        content: ' ';
        bottom: 0;
        left: 0;
        // background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 31%,rgba(255,255,255,1) 100%);
        background: linear-gradient(transparent,white);
    }
    @media (prefers-color-scheme: dark) {
        &::before {
            background: linear-gradient(transparent, $dm-dark);
        }
    }
}
.blog-button-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 rem(15);
}
.blog-more-button {
    margin: 0 auto;
}

.blog-entry-block {
    a {
        color: $body;
        &:hover {
            color: $aqua;
        }
    }
}
.blog-entry-footer {
    @media screen and (max-width:em(728)) {
        padding: 0 20px;
    }

    @media screen and (max-width:em(500)) {
        padding: 0 10px;
    }
}
.footnote-wrapper {
    width: 100%;
    position: relative;
}
.btn-footnote-return {
    color: white;
    font-family: $sans;
    font-size: rem(14);
    background-color: $aqua;
    padding: rem(12);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    max-width: rem(300);
    text-align: center;
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -45px;
    cursor: default;

    @media screen and (max-width:em(750)) {
        padding: rem(10);
        font-size: rem(12);
        max-width: rem(250);
    }
}
.btn-footnote-return--trans {
    transition-delay: 1.5s;
    transition: top .7s ease-in;
}
.btn-footnote-return-active {
    transition: top .5s ease-in 1.5s;
    animation: buttonHover 1.5s infinite cubic-bezier(.49, .49, .65, 1);
    cursor: pointer;
    animation-delay: 1.5s;
    top: rem(90);

    @media screen and (max-width:em(700)) {
        top: rem(70);
    }

    @media screen and (max-width:em(560)) {
        top: rem(97);
    }
}
.blog-tags {
    @media screen and (max-width:em(730)) {
        padding: 0 20px;
    }

    @media screen and (max-width:em(500)) {
        padding: 0 10px;
    }
    li {
        font-family: $sans;
        font-size: em(12);
        letter-spacing: .04em;
        display: inline-block;
        font-weight: 700;
        margin: 0 rem(5) 0 0;
        a {
            color: $aqua;
            background-image: none;

            &:hover {
                color: $dark_aqua;
                background-image: none;
            }
        }
        @media (prefers-color-scheme:dark) {
            a {
                color: $x-light_aqua;
                background-image: none;

                &:hover {
                    color: $aqua;
                    background-image: none;
                }
            }
        }
        &::before {
            content: ' | ';
            display: inline-block;
            color: $med;
            margin: 0 5px 0 0;
        }

        &:nth-child(1) {
            &::before {
                content: '';
                display: none;
            }
        }
    }
}
.blog-related-links-list {
    padding: 20px 0 0 0;

    li {
        font-family: $sans;
        text-align: center;
        line-height: 150%;
        padding: 0 0 10px 0;
        font-size: em(14);

        a {
            color: $aqua;

            &:hover {
                color: $dark_aqua;
            }
        }
    }
}
.blog-related-links-label {
    color: $med;
}
.blog-pullquote {
    margin: 0 0 rem(60) 0;
    padding: rem(40) 0 0;
    text-align: center;

    @media screen and (max-width:em(500)) {
        @include spanner_too (3, 700, 0, 3);
        margin: 0 0 rem(30) 0;
        padding: rem(20) 0 0;
    }
    blockquote {
        border-left: 0;
        margin: 0;
        font-size: rem(22);
        color: $aqua;
        font-family: $sans;
        line-height: 160%;
        padding: 0;

        @media screen and (max-width:em(550)) {
            font-size: em(20);
        }

        @media (prefers-color-scheme: dark) {
            color: $light_aqua;
        }
    }
}
.blog-pullquote--set {
    transform: translateY(30px);
    opacity: 0;

    @media screen and (max-width:rem(450)) {
        transform: translateY(15px)
    }
}
.blog-pullquote--animate {
    transform: translateY(0px);
    opacity: 1;
    transition: transform .3s ease-out, opacity .25s ease-out;
}
.content-nav {
    text-align: center;

    li {
        display: inline-block;
        font-family: $sans;
        font-size: em(14);

        @media screen and (max-width:em(400)) {
            display: block;
            margin: 0 0 12px 0;
        }
        &::after {
            content: ' | ';
            color: $med;
            display: inline-block;
            margin: 0 8px;
        }
        &:nth-child(3) {
            &::after {
                content: '';
            }
        }
    }
}

code {
    display: inline;
    background-color: $haze;
    font-size: em(16);
    padding: 0px rem(5);

    @media (prefers-color-scheme: dark) {
        background-color: #2E2E2E;
    }
}

pre {
    code {
        display: block;
        padding: 20px;
        line-height: 160%;
        margin: 0 0 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        position: relative;
    }
}

.blog-signiture {
    width: 100%;
    margin: 20px 0 20px 0;
    padding: 10px 0 0 0;
    border-top: 1px solid $light;

    @media screen and (max-width:em(750)) {
        padding: 10px 15px;
    }
}

.blog-mugshot {
    width: em(60);
    height: em(60);
    border-radius: 50%;
    float: left;
    margin: 0 15px 0 0;
    overflow: hidden;

    @media screen and (max-width:em(600)) {
        width: em(40);
        height: em(40);
    }

    img {
        width: 100%;
    }
}

.blog-signiture-text {
    font-size: em(15);
    color: $med;
    font-style: italic;

    a {
        background-image: none;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    margin: em(20) auto;
    height: 0;
    overflow: hidden;
    max-width: 100% !important;
    width: 80%;

    @media screen and (max-width:em(450)) {
        width: 90%;
    }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.blog-embed-image {
    display: block;
    width: 100%;
    margin: rem(30) 0;

    .full-width {
        width: 100%;
        max-width: rem(650);
        margin: 0 auto;
    }

    .half-width {
        width: 100%;
        max-width: rem(350);
        margin: 0 auto;
    }
}

.scroll-progress-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0px;
    max-width: rem(700);
    margin: 0 auto;
    left: 0;
    right: 0;
    height: 2px;
    overflow: hidden;

    .scroll-progress {
        position: absolute;
        height: 2px;
        background-color: $aqua;
        left: 0;
        width: 100%;
        transform: translateX(-100%);

        @media (prefers-color-scheme: dark) {
            background-color: $dm-light;
        }
    }

    .scroll-progress--trans {
        transition: transform .2s cubic-bezier(.31, .45, .45, 1.33);
        will-change: transform;
    }
}
.pagination-wrapper {
    display: flex;
    align-content: center;
    flex-direction: column;
}
.pagination {
    display: flex;
    flex-direction: row;
    margin: 0 0 rem(80);
    @media screen and (max-width:em(500)) {
        margin: 0 0 rem(40);
    }
}
.pagination-direction {
    flex-basis: 50%;
    font-family: $sans;
    font-size: rem(18);
    padding: 0 rem(15);
    a {
        background: none;
        color: $aqua;
        @media (prefers-color-scheme: dark) {
            color: $light-aqua;
            &:hover {
                color: $aqua;
            }
        } 
        &:hover {
            color: $dark_aqua;
            @media (prefers-color-scheme: dark) {
                color: $aqua;
            } 
        }
    }
    @media screen and (min-width:em(450)) {
        font-size: rem(24);
    }
}
.pagination .back {
    text-align: right;
    border-right: 1px solid $light;
}
.page-number {
    font-size: rem(16);
    font-family: $sans;
    text-align: center;
}
.arrow-icon-left {
    transform: scaleX(-1);
    filter: FlipH;
}   
.blog-entry.expandable {
    max-height:rem(1000000);
}
.blog-entry.closed {
        height:rem(1500);
        overflow:hidden;
        position: relative;
        margin-bottom:60px;
        &::before {
            width: 100%;
            height: rem(200);
            display: block;
            position: absolute;
            content: ' ';
            bottom: 0px;
            left: 0;
            z-index: 1;
            background: linear-gradient(transparent, white);
        }
        @media (prefers-color-scheme: dark) {
            &::before {
                background: linear-gradient(transparent, $dm-dark);
            }
        }
        .button.blog-more-button.archive {
            position: absolute;
            top: rem(1380);
            bottom: unset;
            left:0;
            right:0;
            z-index: 2;
            display: block;
            border-width:0;
            margin:0 10%;
            width:80%;
            @media screen and (min-width:em(520)) {
                margin: auto;
            }
        }
}
.button.blog-more-button.archive { 
   display:none;
}
.button.portfolio {
    margin-top:40px;
    @media screen and (min-width:em(520)) {
        margin-top:60px;
    }
}
