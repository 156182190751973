.form-field {
  width:100%;
  height:rem(76);
  border:1px solid $light;
  font-size:rem(20);
  padding:rem(30) rem(15);
  font-family:$sans;
  color:$med;
  outline: none;
  border-radius: rem(3);
  position: relative;
}
.button {
	display:block;
	width:100%;
	max-width:500px;
	background-color:white;
	color:$aqua;
	font-family:$sans;
	font-weight:700;
	font-size:em(13);
	padding:30px 0;
	height:75px;
	text-align:center;
	border-radius:4px;
	margin:0 auto 60px;
	letter-spacing:.06em;
	transition:color .2s ease-in, background-color .2s ease-in;
	background-image:none;
	text-shadow:none;
	// box-shadow: 0 0 40px 15px rgba($med, .3);
  box-shadow: 0 rem(18) rem(75) 0 rgba($dark, .3);
	@media screen and (max-width:em(520)) {width:96%;}
		&:hover{
			background-color:$aqua;
			color:white;background-image:none;
			.button-icon {
				fill:white;
				transition: fill .2s;
			}
		}
}
.form-btn {
  border:0px;
  margin:rem(40) auto 0;
}
.form-btn:disabled {
  color:$x-light_aqua;
  // box-shadow: 0 0 15px 8px rgba(153, 153, 153, 0.15);
  box-shadow: 0 rem(9) rem(35) 0 rgba($med, .3);
  &:hover {background-color: white;}
}
.form-btn-mini {
  width:rem(160);
  margin:0;
  transition: color .2s, box-shadow .2s;
  margin:0 auto;
  @media screen and (max-width:rem(700))	{
    width:100%;
    max-width:rem(300);
    padding:rem(10);
    height:rem(60);
    margin:0 auto rem(30);
   }
}
.search-admin-form-field {
  margin:0 0 rem(10) 0;
}
.search-form-field {
  margin:0 rem(30) rem(30) 0;
    @media screen and (max-width:rem(700))	{
      margin:0 0 rem(30);
    }
    &:focus { box-shadow: 0 0 rem(15) $light_aqua; }
}
.search-admin-form-field + label {
  position:absolute;
  z-index: 2;
  top:0;
  left:0;
  padding:rem(25) rem(15);
  font-family:$sans;
  color:$med;
  font-size:rem(20);
  transform: translateY(0px);
}
.form-field-validate + label {
  animation-name: formsLabel;
  animation-duration: .35s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  transition: border .2s;
}
.form-field--error {
  border:1px solid $aqua;
  transition: border .2s;
}
.form-field:disabled {
    border:1px solid $xxlight;
  }
.login-error-message-search-admin {
  opacity:0;
}
.login-error-message--active {
  opacity:1;
  transition: opacity .2s;
}
/* Change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    color:$med !important;
}
