
.teaser-module	{
	@include spanner_too (12, 1100, 0, 12);
	margin:0 0 rem(60) 0;
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}
.teaser-module-head	{
	font-family:$sans;
	font-size:em(24);
	border-bottom:1px solid $light;
	font-weight:800;
	margin:0 0 0 0;
	padding:0 0 rem(2) 0;
		a{
		color:$body;
		background-image:none;
			&:hover {
				color:$aqua;
				background-image:none;
			}
			@media screen and (prefers-color-scheme: dark) {
				color:$light-aqua;
			} 	
	}
	@media screen and (prefers-color-scheme: dark) {
		color:$light-aqua;
		border-bottom:1px solid $dark;
	} 
	@media screen and (max-width:rem(500)) {
        font-size: rem(18);
    }	
}
.teaser-module-subhead	{
	font-family:$sans;
	font-size:em(16);
	font-weight:800;
	margin:0 0 12px 0;
	padding:0;
	@media screen and (prefers-color-scheme: dark) {
		color:white;
	} 
	@media screen and (max-width:rem(500)) {
        font-size: rem(14);
    }	
}
.teaser-module-abstract {
	@include spanner_too (12, 1100, 0, 4);
	float:left;
	margin:rem(5) 0 0 0;
	padding:0 rem(8) 0 0;
		p {
		font-size:em(18);
		}
	@media screen and (max-width:em(750)) {
	@include spanner_too (12, 1100, 0, 12);
	p {
		font-size:em(16);
		}
	}
}
.teaser-module-abstract-link {
	font-family:$sans;
	color:$aqua;
	margin:0 rem(4) 0 0;
	font-weight:700;
	text-decoration:none;
	display:inline-block;
	font-size:em(13);
	text-transform:uppercase;
	letter-spacing:.07em;
	background-image:none;
	&:hover {
		background:none;
		color:$dark-aqua;
		&>svg.teaser-arrow-icon {
			fill:$aqua;
			transition: fill .2s;
		}	
		@media screen and (prefers-color-scheme: dark) {
			color:$aqua;
		}
	}
	@media screen and (prefers-color-scheme: dark) {
		color:$light-aqua;
	} 
	.teaser-arrow-icon {vertical-align: -1px;margin-left:rem(5);}  
}
.teaser-module-art {
	@include spanner_too (12, 1100, 0, 8);
	float:left;
	border-left:1px solid $light;
	@media screen and (max-width:em(750))
		{
		@include spanner_too (12, 1100, 0, 12);
		border-left:0;
		}
}
.teaser-module-mainart {
	margin:0 0 0 0;
	@include spanner_too (8, 678, 0, 8);
	display:block;
	float:left;
	overflow:hidden;
	padding:0;
	position:relative;
	@media screen and (max-width:em(750)) {
		@include spanner_too (8, 750, 0, 8);
		padding:rem(5) 0 0 0;
		}
		img {
		width:100%;
		}
		.med-photo-overlay {
		&:hover {
			box-shadow:0 0 rem(50) rgba($dark, .7) inset;
			}
		}
}
.teaser-module-mainart.med-photo-overlay:after {
	@media screen and (max-width:em(750)) {
		padding-bottom: 59.9%;
	}
}

.med-photo-overlay {
	&::after {
		position:absolute;
		top:0;
		left:0;
		display:block;
		content:' ';
		width:100%;
		padding-bottom:$image-ratio;
		box-shadow:0 0 rem(50) rgba($med, .3) inset;
		transition: box-shadow .2s;
		z-index:2;
		mix-blend-mode: multiply;
		@media (prefers-color-scheme: dark) {
			box-shadow:0 0 rem(50) rgba($dm-dark, .7) inset;
		}
	}
}
.wide-photo-overlay{
	&::after{
		position:absolute;
		top:0;
		display:block;
		content:' ';
		width:100%;
		padding-bottom:42%;
		box-shadow:0 0 rem(50) rgba($med, .3) inset;
		transition: box-shadow .2s;
		z-index:10;
	//	mix-blend-mode:difference;
		}
}
.round-photo-overlay{
	&::after{
		position:absolute;
		top:0;
		display:block;
		content:' ';
		width:100%;
		padding-bottom:100%;
		box-shadow:0 0 rem(50) rgba($med, .3) inset;
		transition: box-shadow .2s;
		z-index:2;
		mix-blend-mode:difference;
		border-radius:50%;
		}
}
.hero-art-secondary {
	width:100%;
	max-width:rem(1600);
	padding:0 0 20%;
	margin:0 auto;
	background-attachment:fixed;
	background-size:100% auto;
	position:relative;
	min-height:2rem(50);
	@media screen and (max-width:em(750)) {
		height:2rem(50);
		}
	@media screen and (max-width:em(425))
		{
		height:2rem(50);
		}
}
.section-header-index {
	font-size:em(36);
	font-family:$sans;
	font-weight:700;
	text-align:center;
	color:$aqua;
	margin-bottom:rem(10);
	@media screen and (max-width:em(500)) {
		font-size:em(30);
		}
		a {
		background-image:none;
		color:$aqua;
		}
}
.blog-teaser-wrapper {
	margin: rem(60) auto 0;
	max-width:700px;
	width:100%;
	@media screen and (max-width:em(500)) {
			margin: rem(40) 0 0;
			padding:0 0 0 rem(5);
		}
}
.blog-teaser-list {
	width:100%;
	display:flex;
	margin:0 0 rem(80);
	flex-wrap: wrap;
	flex-direction:row;
}
.blog-teaser-inside {
	flex-direction: row-reverse;
}
.blog-teaser-label {
	margin:0 0 rem(10) rem(5);
}
// if there are four items
.blog-teaser-item:first-child:nth-last-child(n+4),
.blog-teaser-item:first-child:nth-last-child(n+4) ~ * {
	flex-basis:calc(19% - 5px);
	flex-grow:1;

	&:nth-child(1) {order:2 ; flex-grow:1.5; 	flex-basis:calc(25% - 5px);}
	&:nth-child(2) {order:1}
	&:nth-child(3) {order:3}
	&:nth-child(4) {order:4}

	@media screen and (max-width:rem(650)) {
		 padding:rem(5) rem(5) 0 rem(5);
	}
	@media screen and (max-width:rem(550)) {
		flex-basis:calc(50% - 5px);
		flex-grow:0;
		&:nth-child(1) {flex-basis:calc(50% - 5px);order:3}
		&:nth-child(3) {order:2}
	}
}
// if there are fewer than four
.blog-teaser-item {
	flex-basis:25%;
	margin-right:5px;
	padding:rem(5);
	border-radius:2px;
	flex-grow:0;
	opacity:0;
	transform: translateY(rem(30));

	@media screen and (max-width:rem(650)) {
		 padding:rem(5) rem(5) 0 rem(5);
	}
	@media screen and (max-width:rem(500)) {
		transform: translateY(rem(20));
		flex-basis:calc(50% - 5px);
		flex-grow:0;
		&:nth-child(1) {flex-basis:calc(50% - 5px);order:3}
		&:nth-child(3) {order:2}
	}
	a {
		background:none;
		display:inline-block;

		&:hover {.teaser-arrow-icon{fill:$aqua;transition:fill .2s} }
	}
}
.blog-teaser-image {
	padding:59% 0 0;
	margin:0;
	position:relative;
		img {position: absolute;top:0}
}
.blog-teaser-wrapper--active {
	.blog-teaser-item {
		opacity:1;
		transform: translateY(0);
		transition:opacity .2s ease-in, transform .4s cubic-bezier(.55,.3,.38,1.55) ;

		&:nth-child(1) {transition-delay: .2s}
		&:nth-child(2) {}
		&:nth-child(3) {transition-delay: .4s}
		&:nth-child(4) {transition-delay: .6s}

		@media screen and (max-width:rem(550)) {
			&:nth-child(2) {}
			&:nth-child(3) {transition-delay: .2s;}
			&:nth-child(1) {transition-delay: .4s;}
			&:nth-child(4) {transition-delay: .6s;}
		}
	}
}
.blog-teaser-hed {
	line-height:120%;
	margin:rem(5) 0 rem(12);
	font-size:rem(15);
}
.teaser-arrow-icon {
	display:inline-block;
	width:rem(24);
	height:rem(10);
	vertical-align:middle;
	fill:$light-aqua;
}
