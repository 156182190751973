@media (prefers-color-scheme: dark) {
    body {
        background-color: $dm-dark;
        color: $dm-light;
    }
}

figure {
    img {
        width: 100%;
        background-color: $xxlight;
    }
}

img {
    display: block;

    a {
        border: 0;
    }
}

a {
    img {
        border: 0;
    }
    &:focus {
        box-shadow: none;
        background: $x-light-aqua !important;
        box-shadow: 0 0 rem(8) $x-light-aqua;
        outline-style: none;
        color: black !important;
    }
}

p {
    font-family: $droid;
    font-size: em(13.3);
    line-height: 1.65;
    margin: 0 0 em(15) 0;
}

h1,
h2,
h3,
h4 {
    font-family: $sans;
    font-weight: 700;
}

ul {
    padding: 0px;

    li {
        list-style-type: none;
    }
}

.section-header {
    font-size: rem(30);
    text-transform: lowercase;
    font-family: $sans;
    font-weight: bold;
    text-align: center;
    color: $aqua;
    transform: font-size .3s;

    @media screen and (max-width:em(500)) {
        font-size: em(22);
    }

    span {
        color: $med;
    }

    a {
        background-image: none;
    }
}

sup {
    font-size: rem(10);
    vertical-align: middle;
    display: inline-block;

    a {
        display: block;
        padding: 0 rem(8);
        background: none, $haze;
        border-radius: rem(3);
        line-height: rem(18);
        margin: 0 0 0 rem(3);
        font-weight: bold;
        font-family: $sans;
        text-shadow: none;
        color: $aqua;
        transition: background-color .5s, color .5s;

        @media (prefers-color-scheme: dark) {
            color: $x-light-aqua;
            background: none, $body;
        }

        &:hover {
            background: none, $aqua;
            transition: background-color .2s;
            text-shadow: none;
            color: #fff;
        }
    }
}

p,
h1,
h2,
h3,
h4,
li,
a,
code,
sup,
span {
    &::selection {
        background: lighten($aqua, 50);
        /* WebKit/Blink Browsers */
    }

    &::-moz-selection {
        background: #ffb7b7;
        /* Gecko Browsers */
    }
}

.footnote-link-selected {
    a {
        background-color: $aqua;
        color: white;
    }
}

.footnote-link-active {
    a {
        //	animation: listItemActive 1s 3;
        background-color: $aqua;
        color: white;
        transition: background-color .5s, color .5s;
    }
}

.footnote-paragraph-active {
    animation: paragraphActive 1s 4;
    border-radius: 3px;
}

ol {
    margin-left: 0;
    padding: rem(20) 0 rem(30) 0;
    list-style-type: none;
    border-top: 1px solid $light;

    @media (prefers-color-scheme: dark) {
        border-top: 1px solid $med;
    }
}

ol li {
    counter-increment: step-counter;
    margin: 0 0 rem(18) 0;
    font-size: rem(14);
    line-height: 160%;
    color: $dark;

    @media (prefers-color-scheme: dark) {
        color: $light;
    }
}

ol li::before {
    content: counter(step-counter);
    margin-right: rem(8);
    font-size: rem(10);
    background-color: $haze;
    color: $aqua;
    font-weight: bold;
    padding: 2px 8px;
    border-radius: 3px;
    font-family: $sans;

    @media (prefers-color-scheme: dark) {
        color: $x-light-aqua;
        background-color: $body;
    }
}

.list-item-active {
    color: $body;
}

.list-item-active::before {
    animation: listItemActive 1s 3;
    background-color: $aqua;
    color: white;
}

@keyframes listItemActive {
    0% {
        background-color: $aqua;
    }

    70% {
        background-color: $light_aqua;
    }

    100% {
        background-color: $aqua;
    }
}

@keyframes paragraphActive {
    0% {
        background-color: transparent;
    }

    70% {
        background-color: $haze;
    }

    100% {
        background-color: transparent;
    }
}

.button {
    display: block;
    width: 100%;
    max-width: 500px;
    background-color: white;
    color: $aqua;
    font-family: $sans;
    font-weight: 700;
    font-size: em(13);
    padding: 30px 0;
    height: 75px;
    text-align: center;
    border-radius: 4px;
    margin: 0 auto 60px;
    letter-spacing: .06em;
    transition: color .2s ease-in, background-color .2s ease-in;
    background-image: none;
    text-shadow: none;
    box-shadow: 0 0 40px 15px rgba($med, .3);

    @media screen and (max-width:em(520)) {
        width: 96%;
    }

    @media (prefers-color-scheme: dark) {
        box-shadow: none;
        background-color: $dm-light;
    }
    &:focus {
        outline-style: auto;
        outline-color: $aqua;
        outline-width: 2px;
    }
    &:hover {
        background-color: $aqua;
        color: white;
        background-image: none;
        .button-icon {
            fill: white;
            transition: fill .2s;
        }
    }
}
img {
    font-size: rem(12);
}