.gallery-count {
    font-size:rem(14);
    color: $light;
    font-weight:bold;
    font-family:$sans;
    text-align:right;
    margin:0 rem(12) rem(6) 0;
    color: $dark;
    @media (prefers-color-scheme: dark) {
        color: $x-light-aqua;
    }  
    @media screen and (min-width:rem(940)) {
        margin:0 0 rem(6);
    } 
}
.gallery-wrapper {
    width:100%;
    position:relative;
    @media screen and (min-width:rem(720)) {
        width:90vw;
        margin: 0 5vw;
    } 
    @media screen and (min-width:rem(1870)) {
        max-width:rem(1800);
        margin:0 auto;
    } 
    .gallery {
        width: 100%;
        position:relative;
        background-color: $xxlight;
        height:58.9vw;
        @media screen and (min-width:rem(720)) {
            height:calc(90vw * .589)
        }  
        @media screen and (min-width:rem(1870)) {
            height: calc(1800px * .589)
         } 
         @media (prefers-color-scheme: dark) {
            background-color: $dark;
        } 
    }  
    .slide {
        margin:0;
        display:block;
        width:0;
        height:0;
        position:absolute;
        right:0;
        transition:opacity .5s;
        opacity:0;
        z-index:2;
        &.center {
            width:100%;
            height:auto;
            position:absolute;
            left:0;
            opacity:100;  
            transition:opacity .5s;
            right:0;
            margin: 0 auto;
        }
        &.move-out {
            width:100%;
            height:auto;   
            opacity:0;
            transition:opacity .2s; 
            transition-delay:.3s;
            margin: 0 auto;
        }
    } 
} 
button.slide-button {
    width:rem(60);
    height:rem(60);
    border:0;
    background-color:white;
    border-radius:50%;
    position: absolute;
    top:calc(100% + 10px);
    z-index:9;
    transition: background-color .2s, transform .2s ease-in-out;
    box-shadow: 0 0 rem(50) 0 rgba($dark, .4);
    cursor:pointer;
    &::after {
        display:block;
        content:"";
        width: 0; 
        height: 0; 
        position:absolute;  
        top:rem(24);
        left:rem(27);
        transition: border-color .2s;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }
    &.left {
      right:calc(50% + 30px);
      &:after {
        border-right: 6px solid $dark_aqua;
      }
    }
    &.right {
        left:calc(50% + 30px);
      &:after {
        border-left: 6px solid $dark_aqua;
      }
    }
    span {
      width:0;
      height:0;
      overflow:hidden;
      position:absolute;
      right:-100000000px;
    }
    &:hover {
        background-color:$aqua;
        &.right {
            &:after {
             border-left: 6px solid white;
            }   
    }
    &.left {
        &:after {
         border-right: 6px solid white;
        }   
}
    &:active {
        transform:scale(92%); 
        transition: transform .2s ease-in-out;
    }
  } 
    @media (prefers-color-scheme: dark) {
        background-color: $x-light-aqua;
        box-shadow: 0 0 1px $dark-aqua;
        &:hover {
            background-color:$dark_aqua;
        }
        &.left {
            &:after {
                border-right: 6px solid $dark_aqua;
            }
        }
        &.right {
            &:after {
                border-left: 6px solid $dark_aqua;
            } 
        }
    } 
    @media screen and (min-width:rem(940)) {
        top:calc(50% - rem(30));
        &.left {
            left:-30px;
          }
          &.right {
            left:calc(100% - rem(30));
          }
    }    
}