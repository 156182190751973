.no-js {
	.triple-module-head {
		opacity:1;
		line-height:150%;
		top:0;
		transform: scale(1,1);
	}
.main-header-logo--display{
	opacity:1;
	bottom:150px;
	transform: scale(1,1);
	@media screen and (max-width:em(800)) {
		bottom:130px;
		}
	@media screen and (max-width:em(425)) {
		bottom:225px;
		}
}
.nav-list--display {
	bottom:rem(60);
	opacity:1;
	transform: scale(1,1);
	@media screen and (max-width:em(425)) {
		top:160px;
		}
		li {
			@media screen and (max-width:em(425)) {
				padding:3px 12px 5px 0;
			}
		}
	}
	.site-footer {
		opacity:1;
		.mail-link, .twitter-link, .facebook-link, .github-link {
			opacity:1;
			transform: scale(1,1);
		}
	}
	.footer-links {
			li {
				a {
					.footer-icon {
						fill:$aqua;
					}
				}
			}
	}
	.footer-icon {
		fill:$light;
	}
	.blog-teaser-item {
		transform: translateY(0);
		opacity:1;
	}
	.loading-overlay--preset {
		&::after {content:'';display:none;}
	}
	.blog-entry.closed .button.blog-more-button.archive {
		display:none;
	}
	.blog-entry.closed.expandable {
		height:auto;
	}
	.blog-entry.closed::before {
		display:none;
	}

}

