.about-container {
	@extend .teaser-module-container !optional;
	width:100%;
	max-width:rem(700);
	margin:0 auto;
	padding:0;
	@media screen and (max-width:rem(740)){
		padding:0 rem(20);
	 }
	 @media screen and (max-width:rem(500)){
 		padding:0 rem(15);
 	 }  
}
.about-information { 
	p {
	font-size:rem(16);
	line-height:150%;
			@media screen and (max-width:rem(650))	{
				column-count: 1;
			}
			@media screen and (max-width:rem(500))	{
				font-size:rem(15);
				line-height:150%;
			}
			&:nth-child(2):first-letter {
				float: left;
				color: $med;
				font-size: rem(53);
				line-height: 80%;
				margin:rem(5) rem(5) 0 0;
				font-family: $sans;
					@media screen and (max-width:em(500)) { font-size: rem(53); }
				}
			}
	}
.about-mugshot {
	width:rem(120);
	height:rem(120);
	margin:rem(4) auto rem(30);
	border-radius:50%;
	overflow:hidden;
	border:1px solid $light;
}
