$droid: 'Droid Serif',
georgia,
times,
serif;
$sans: 'Ginger',
helvetica,
arial,
sans-serif;

/* colors */
$body:#3C3C3C;
$aqua:#2989a2;
$dark_aqua:darken(#4094aa, 20%);
$light_aqua:#9db9c1;
$x-light-aqua:#c9e1e8;
$haze:#F6F5F1;

$dark:#666;
$med:#999;
$light:#d0d0d0;
$xxlight:#f5f5f5;
$xlight:#e4e2e2;

$dm-dark:#222;
$dm-light:#e6e6e6;

$image-ratio: 58.9%;
